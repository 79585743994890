import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ChoiceDoorsView from '../views/ChoiceDoorsView.vue';
import OrderView from '../views/OrderView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: "Конструктор дверей - Главная",
      metaTags: [
          {
            name: 'referrer',
            content: 'no-referrer-when-downgrade',
          }
      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      title: "Конструктор дверей - О нас",
    }
  },
  {
    path: '/choice',
    name: 'choice',
    component: ChoiceDoorsView,
    meta: {
      title: "Конструктор дверей",
      metaTags: [
        {
          name: 'referrer',
          content: 'no-referrer-when-downgrade',
        }
      ]
    }
  },
  {
    path: '/order',
    name: 'order',
    component: OrderView,
    meta: {
      title: "Конструктор дверей - Заказ",
      metaTags: [
        {
          name: 'referrer',
          content: 'no-referrer-when-downgrade',
        }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  if(!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  }).forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
