<template>
  <div class="content">
    <router-view/>
  </div>
  <Footer />
</template>

<script>
  import Footer from '@/components/include/Footer.vue'
  import {mapGetters, mapMutations} from "vuex";
  //import FabricComponent from '@/components/FabricComponent.vue'

  export default {
    name: 'HomeView',
    components: {
      Footer
    },
    created() {
      this.saveReferrerUrl();
    },
    computed: {
      ...mapGetters(['getReferrerLink']),
    },
    methods: {
      ...mapMutations(['setReferrerLink']),
      saveReferrerUrl(){
        //console.log('save referrer: ' + document.referrer);
        if (!this.getReferrerLink && document.referrer !== "") {
          this.setReferrerLink(document.referrer);
          console.log('save referrer: ' + document.referrer);
          this.$store.dispatch('getDoorInfo', {
            link: document.referrer
          })
        }
      }
    }
  }
</script>

<style>

</style>
