<template lang="html">
  <div class='popup' id="publicOferta">
    <p class='popup-title'>
      Договор публичной оферты о продаже товаров Интернет-магазина «Linedoors.com» № 001
    </p>
    <p>
      1.Общие положения:<br>
      1.1. ООО "Линия Дверей", в лице генерального директора Мясниковой М.И., далее «Продавец», публикует Публичную оферту о реализации Товаров, размещенных на официальном интернет-сайте Продавца <a href="https://linedoors.com/" target="_blank">https://linedoors.com/</a><br>
      1.2. В соответствии со статьей 437 Гражданского Кодекса Российской Федерации (ГК РФ) данный документ является публичной офертой, и в случае принятия изложенных ниже условий физическое лицо, производящее акцепт этой оферты, осуществляет оплату Услуги Продавца в соответствии с условиями настоящего Договора. В соответствии с пунктом 3 статьи 438 ГК РФ, оплата Услуги Покупателем является акцептом оферты, что считается равносильным заключению Договора на условиях, изложенных в оферте.<br>
      1.3. На основании вышеизложенного внимательно ознакомьтесь с текстом публичной оферты, и, если вы не согласны с каким-либо пунктом оферты, Вам предлагается отказаться от использования Услуг, предоставляемых Продавцом.<br>
      1.4. В настоящей оферте, если контекст не требует иного, нижеприведенные термины имеют следующие значения: • «Оферта» – публичное предложение Продавца, адресованное любому физическому лицу (гражданину), заключить с ним договор на существующих условиях, содержащихся в Договоре, включая все его приложения. • «Покупатель» – физическое лицо, заключившее с Продавцом Договор на условиях, содержащихся в Договоре. • «Акцепт» – полное и безоговорочное принятие Покупателем условий Договора. <br>• «Услуга» – перечень наименований ассортимента, представленный на официальном интернет-сайте. • «Заказ» – отдельные позиции из ассортиментного перечня Услуг, указанные Покупателем при оформлении заявки на интернет-сайте или через Оператора.<br>
      2. Предмет договора<br>
      2.1. Продавец реализует Услугу в соответствии с действующим прейскурантом, опубликованным на интернет-сайте Продавца <a href="https://linedoors.com/" target="_blank">https://linedoors.com/</a>», а Покупатель производит оплату и принимает Услугу в соответствии с условиями настоящего Договора.<br>
      2.2. Настоящий Договор и приложения к нему являются официальными документами Продавца и неотъемлемой частью оферты.<br>
      3. Оформление Заказа<br>
      3.1. Заказ Услуги осуществляется Покупателем через Интернет-сайт <a href="https://linedoors.com/" target="_blank">https://linedoors.com/</a>.<br>
      3.2. При регистрации на интернет-сайте Продавца Покупатель обязуется предоставить следующую регистрационную информацию о себе: • адрес электронной почты (для автоматической отгрузки документации).
      <br>3.3. При оформлении Заказа через Оператора Покупатель обязуется предоставить информацию, указанную в п. 3.2. настоящего Договора. Принятие Покупателем условий настоящего Договора осуществляется посредством внесения Покупателем соответствующих данных в регистрационную форму на Интернет-сайте или при оформлении Заказа через Оператора. Покупатель имеет право редактировать регистрационную информацию о себе. Оператор не изменяет и не редактирует регистрационную информацию о Покупателе без согласия последнего. Продавец обязуется не сообщать данные Покупателя, указанные при регистрации на сайте <a href="https://linedoors.com/" target="_blank">https://linedoors.com/</a> и при оформлении Заказа, лицам, не имеющим отношения к исполнению Заказа. Утвердив Заказ выбранной Услуги, Покупатель предоставляет Оператору необходимую информацию в соответствии с порядком, указанном в п. 3.2. настоящего Договора.
      <br>3.4. Продавец и Оператор не несут ответственности за содержание и достоверность информации, предоставленной Покупателем при оформлении Заказа.
      <br>3.5. Покупатель несёт ответственность за достоверность предоставленной информации при оформлении Заказа.
      <br>3.6. Оплата Покупателем самостоятельно оформленного на интернет-сайте Заказа означает согласие Покупателя с условиями настоящего Договора. День оплаты Заказа является датой заключения Договора между Продавцом и Покупателем.
      <br>3.7. Все информационные материалы, представленные на сайте <a href="https://linedoors.com/" target="_blank">https://linedoors.com/</a>, носят справочный характер и не могут в полной мере передавать достоверную информацию об определенных свойствах и характеристиках Услуги. В случае возникновения у Покупателя вопросов, касающихся Услуги, перед оформлением Заказа ему необходимо обратиться за консультацией к Оператору.
      <br>4. Сроки исполнения Заказа
      <br>4.1. Срок исполнения Заказа зависит от типа Услуги и времени, необходимого на обработку Заказа. Срок исполнения Заказа в исключительных случаях может быть оговорен с Покупателем индивидуально в зависимости от типа предоставляемых Услуг. В случае невозможности оказания Услуги Продавцом, в том числе по причинам, не зависящим от последнего, Продавец вправе аннулировать указанную Услугу из Заказа Покупателя. Продавец обязуется уведомить Покупателя об изменении его Заказа через Оператора.
      <br>4.2. Заказ считается выполненным в момент принятия услуги Покупателем.
      <br>4.3. В случае предоставления Покупателем недостоверной информации его контактных данных Продавец за ненадлежащее исполнение Заказа ответственности не несет.
      <br>4.4. В случае ненадлежащего исполнения Заказа по вине Продавца повторная реализация Заказа осуществляется бесплатно.
      <br>5. Оплата Заказа
      <br>5.1. Оплата исполненного Заказа осуществляется путем передачи Покупателем денежных средств сотруднику Продавца. Подтверждением оплаты исполненного Заказа является чек.
      <br>5.2. Цены на Услуги, указанные на интернет-сайте «<a href="https://linedoors.com/" target="_blank">https://linedoors.com/</a>», могут быть изменены Продавцом в одностороннем порядке без уведомления Покупателя. В случае изменения цены на заказанные Услуги, Оператор обязуется в кратчайшие сроки проинформировать Покупателя о таком изменении. Покупатель вправе подтвердить либо аннулировать Заказ. В случае отсутствия связи с Покупателем Заказ считается аннулированным в течение 14 календарных дней с момента оформления.
      <br>5.3.Денежные средства принимаются двумя способами: наличными, безналичным платежом
      <br>6. Возврат Заказа
      <br>6.1. В соответствии с п. 4. ст. 26.1. Закона РФ № 2300-I «О Защите прав потребителей» Покупатель вправе отказаться от заказанной Услуги в любое время до момента исполнения Заказа.
      <br>6.2. Покупатель не вправе отказаться от оплаченного Заказа (или его части) надлежащего качества, имеющего индивидуально определённые свойства.
      <br>6.3. В случае предоставления Услуги ненадлежащего качества, Покупатель обязуется обратиться к Продавцу в максимально короткие сроки.
      <br>6.4. В соответствии со ст. 22 Закона РФ № 2300-I «О Защите прав потребителей» уплаченная Покупателем сумма за Услугу ненадлежащего качества подлежит возврату Покупателю в течение 10 календарных дней с момента предъявления соответствующего требования. Возврат денежных средств осуществляется наличными в офисе Продавца. В случае, если Услуга был оплачена через систему электронных платежей, то возврат денежных средств осуществляется на электронный счет Покупателя в течение 5-ти рабочих дней.
      <br>6.5. Для возврата денежных средств за Услугу надлежащего качества (оказанную в соответствии с описанием, размещенным на интернет-сайте <a href="https://linedoors.com/" target="_blank">https://linedoors.com/</a>), должны быть выполнены следующие условия:
      <br>- документация находится в нечитаемом состоянии
      <br>В остальных случаях возврат денежных средств за услугу надлежащего качества невозможен.
      <br>6.6. Для возврата денежных средств за услугу не надлежащего качества (оказанную не в соответствии с описанием, размещенным на интернет-сайте <a href="https://linedoors.com/" target="_blank">https://linedoors.com/</a>), составляются накладная или акт о возврате денежных средств, в котором указываются:
      <br>- полное фирменное наименование Продавца;
      <br>- фамилия, имя, отчество и паспортные данные Покупателя;
      <br>- наименование Услуги;
      <br>- причина возврата денежных средств за Услугу;
      <br>- дата оказания Услуги;
      <br>- сумма, подлежащая возврату;
      <br>- подписи Продавца и Покупателя.
      <br>6.7 Для возврата денежных средств в случае отсутствия факта начала оказания услуги должны быть выполнены следующие условия:
      <br>- предоставить копию интернет-чека об оплате документации
      <br>- предоставить экземпляр нечитаемой документации
      <br>В остальных случаях возврат денежных средств за не оказанную Услугу невозможен.
      <br>6.8. Возврат стоимости Услуги производится не позднее чем через 10 (десять) дней с даты предоставления Покупателем соответствующего требования. 6.9. Для возврата денежных средств на банковскую карту Заказчику необходимо заполнить «Заявление о возврате денежных средств», которое высылается по требованию Компанией на электронный адрес Заказчика, и оправить его вместе с приложением копии паспорта по адресу: mim со дня получения «Заявление о возврате денежных средств» Компанией.
      <br>6.10. Для возврата денежных средств, зачисленных на расчетный счет Компании ошибочно посредством платежных систем, Заказчик должен обратиться с письменным заявлением и приложением копии паспорта и чеков/квитанций, подтверждающих ошибочное зачисление. Данное заявление необходимо направить по адресу: zakaz@tipdoc.ru. После получения письменного заявления с приложением копии паспорта и чеков/квитанций Компания производит возврат в срок до 10 (десяти) рабочих дней со дня получения 3аявления на расчетный счет Заказчика, указанный в заявлении. В этом случае, сумма возврата будет равняться стоимости Заказа.
      <br>6.11. Срок рассмотрения Заявления и возврата денежных средств Заказчику начинает исчисляться с момента получения Компанией Заявления и рассчитывается в рабочих днях без учета праздников/выходных дней. Если заявление поступило Компании после 18.00 рабочего дня или в праздничный/выходной день, моментом получения Компанией Заявления считается следующий рабочий день.
      <br>6.12. Требование о возврате Покупатель должен предоставить Продавцу либо в письменном виде по адресу г.Москва, ул. Коминтерна , д.7 корпус 2, либо в электронном на адрес электронной почты zakaz@tipdoc.ru. Требование может быть составлено в свободной форме с обязательным указанием контактных данных Покупателя, совпадающих с указанными при заказе Услуги, наименования возвращаемого Услуги и причин возврата. Покупатель вправе задать все возникшие вопросы Продавцу по телефону 8(495) 961-25-17.
      <br>7. Авторские права
      <br>7.1. Вся текстовая информация и графические изображения, размещенные на интернет-сайте <a href="https://linedoors.com/" target="_blank">https://linedoors.com/</a>, являются собственностью Продавца и/или его поставщиков.
      <br>8. Права, обязанности и ответственность
      <br>8.1. Продавец не несет ответственности за ненадлежащее использование Услуг Покупателем, заказанных на интернет-сайте или через Оператора.
      <br>8.2. Продавец вправе передавать свои права и обязанности по исполнению Заказов третьим лицам.
      <br>8.3. Продавец имеет право на осуществление записи телефонных переговоров с Покупателем. В соответствии с п. 4 ст. 16 Федерального закона «Об информации, информационных технологиях и о защите информации» Продавец обязуется: предотвращать попытки несанкционированного доступа к информации и/или передачу ее лицам, не имеющим непосредственного отношения к исполнению Заказов; своевременно обнаруживать и пресекать такие факты. Телефонные разговоры записываются в целях осуществления контроля деятельности Оператора и контроля качества исполнения Заказов.
      <br>8.4. Все претензии по ненадлежащему исполнению заказа Покупатель вправе направить на адрес электронной почты, указанный на интернет-сайте <a href="https://linedoors.com/" target="_blank">https://linedoors.com/</a>, в разделе контакты. Вся поступившая информация обрабатывается в кратчайшие сроки.

        <br><br>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Мясникова М.И.


    </p>
  </div>
</template>

<script>
export default {
  name: "PublicOfertaPopup"
}
</script>

<style lang="scss" scoped>
  .popup{
    background: #fff;
    padding: 30px 37px 30px;
    z-index: 601;
    margin-top: 30px;
    border-radius: 6px;
    line-height: 23px;
    width: 900px;
    height: fit-content;
    font-family: "Times New Roman", Times;

    .popup-title{
      font-size: 21px;
      line-height: 27px;
      margin-bottom: 11px;
    }

    p{
      font-size: 15.5px;
      line-height: 23px;

      a{
        color: #2285c4;
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 1000px){
    .popup{
      width: 650px !important;
    }
  }

  @media screen and (max-width: 767px){
    .popup{
      width: calc(100% - 30px) !important;
    }
  }
</style>
