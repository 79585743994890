import axios from 'axios';
import api from './config.js';
import { baseUrlApi } from './config.js';

const $api = axios.create({
    withCredentials: false,
    baseURL: baseUrlApi
});

export const getCategories = async function() {
    return await $api.get(api.category.get);
}

export const getColors = async function() {
    return await $api.get(api.color.get);
}

export const getProductsByCategoryId = async function(categoryId) {
  if (categoryId == null) {
    return await $api.get(api.product.getByCategoryId);
  }else{
    return await $api.get(api.product.getByCategoryId + "/" + categoryId);
  }
}

export const getSettings = async function() {
    return await $api.get(api.settings.get);
}

export const getProperties = async function() {
    return await $api.get(api.property.get);
}

export const sendMessageOrder = async function(data) {
    return await $api.post(api.mail.order, data);
}

export const getProductIdsByProperties = async function(data) {
    return await $api.post(api.property.getProductIdsByProperties, data);
}

export const sendMessageCallMeLater = async function(data) {
    return await $api.post(api.mail.callMeLater, data);
}

export const getColorsInCategory = async function(categoryId) {
    return await $api.get(api.color.getColorsInCategory + categoryId);
}

export const getPropertiesTextValueFiltered = async function(data) {
    return await $api.post(api.property.getPropertiesTextValueFiltered, data);
}

export const getProductByLink = async function(data) {
    return await $api.post(api.product.getByLink, data);
}
