<template>
  <header class="header">
    <div class="container">
      <div class="header-wrapper">
        <div class="header-wrapper-item">
          <div class="header-wrapper-item-wrapper">
            <!--<a href="#" class='burger-button-link'>
              <div class="burger-button">
                <div class="burger-button-wrapper">
                  <img src="../../assets/icon/burger.svg" alt="">
                </div>
              </div>
            </a>-->
            <div style='cursor:default'>
              <div class="home-header-button" style='cursor:default'>
                <div class="button-wrapper" style='cursor:default'>
                  Фабрика Линия Дверей
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header-wrapper-item">
          <div class="header-wrapper-item-wrapper" @click="$router.push({ name: 'home' })">
            <img class='logo' src="../../assets/logo.svg" alt="">
          </div>
        </div>
        <div class="header-wrapper-item-right header-wrapper-item">
          <div class="header-wrapper-item-wrapper">
            <div class="header-contacts">
              <div class="phone-wrapper">
                <div class="phone-button" @mouseover="phoneBlockOpen">
                  <img src="../../assets/icon/vector.svg" alt="">
                </div>
                <div v-if='this.phoneBlockVisible' class="phone-button-content" @mouseleave="phoneBlockOpen">
                  <div class="phone-button-content-item">
                    <a href="tel:+7 499 707-79-96">
                      +7 499 707-79-96
                      <p>
                        Консультации по решению
                      </p>
                    </a>
                  </div>
                  <div class="phone-button-content-item">
                    <a href="tel:+7 499 707-79-58">
                      +7 499 707-79-58
                    </a>
                  </div>
                  <div class="phone-button-content-item">
                    <a href="tel:+7 4732 00-69-17">
                      +7 4732 00-69-17
                      <p>
                        Воронеж
                      </p>
                    </a>
                  </div>
                  <div class="phone-button-content-item" >
                    <a class='email' href="mailto:info@linedoors.com">
                      info@linedoors.com
                    </a>
                  </div>
                  <div  class="phone-button-content-item">
                    <a href="https://vk.com/line_doors_official" target="_blank">
                      <img class='social-link' src="../../assets/icon/vk.png" alt="">
                    </a>
                    <a href="https://www.youtube.com/channel/UC4eU1QhMVChC2au26LGo-SA" target="_blank">
                      <img class='social-link' src="../../assets/icon/youtube.png" alt="">
                    </a>
                    <a href="https://t.me/linedoors" target="_blank">
                      <img class='social-link' src="../../assets/icon/telegram.png" alt="">
                    </a>
                  </div>
                </div>
                <a class='button-link' href="tel:+7 499 707-79-96">
                  <div class="phone-number">
                    +7 499 707-79-96
                  </div>
                </a>
              </div>
              <a class='button-link' href="mailto:info@linedoors.com">
                <div class="email-link">
                  info@linedoors.com
                </div>
              </a>
            </div>
            <a href="#" class='burger-button-link burger-button-link-right' @click='callMeLaterPopupOpen()'>
              <div class="burger-button">
                <div class="burger-button-wrapper">
                  <img src="../../assets/icon/phone.svg" alt="">
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div class="popup_dark" v-if='this.callMeLaterPopupVisible' @click='callMeLaterPopupOpen()'></div>
  <div class="popup_wrapper" v-if='this.callMeLaterPopupVisible'>
    <CallMeLaterPopup v-if='this.callMeLaterPopupVisible'/>
  </div>
  <div class="popup_close" v-if='this.callMeLaterPopupVisible' @click='callMeLaterPopupOpen()'><img src="@/assets/icon/modal-close.png"></div>
  <div class="popup_close_mobile" v-if='this.callMeLaterPopupVisible' @click='callMeLaterPopupOpen()'><img src="@/assets/icon/cross_mobb.png"></div>



</template>

<script>
  import CallMeLaterPopup from './CallMeLaterPopup';

  export default {
    name: 'Header',
    components: {
      CallMeLaterPopup,
    },
    data(){
      return{
        phoneBlockVisible: false,
        callMeLaterPopupVisible: false,
      }
    },
    methods: {

      phoneBlockOpen(){
        this.phoneBlockVisible = !this.phoneBlockVisible;
      },
      callMeLaterPopupOpen(){
        this.callMeLaterPopupVisible = !this.callMeLaterPopupVisible;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .logo{
    cursor: pointer;
  }
  .popup_close_mobile{
    display: none;
  }
  .popup_wrapper{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .popup_close{
    position: absolute;
    z-index: 501;
    top: 30px;
    left: calc(50% + 200px);
    cursor: pointer;
  }

  .popup_dark{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    z-index: 500;
  }

  .home-header-button {
    padding: 0;
    background: none;
  }

  .phone-wrapper{
    display: flex;
  }
  .phone-button{
    background: rgba(255, 255, 255, 0.21);
    border-radius: 1.6767px;
    display: initial;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 10px;
    margin-left: -20px;
    cursor: pointer;

    &-content{


      margin-left: -5%;
      z-index: 5000;
      position: absolute;
      background: #fff;
      box-shadow: 0 0 10px 5px rgb(0 0 0 / 27%);
      border-radius: 2px;

      &-item{
        padding: 19px;
        font-size: 23px;
        line-height: 23px;
        color: #000;
        white-space: nowrap;
        border-bottom: 1px solid #d1dce0;

        .social-link{
          height: 30px;
          margin-right: 10px
        }

        .email{
          color: #1a8fe9;
          border-bottom: 1px solid #dedede;
          font-size: 15px;
        }

        a{
          text-decoration: none;
          color: black;
        }

        p{
          font-size: 14px;
          line-height: 18px;
          color: #898989;
          margin-top: 2px;
        }
      }
    }
  }
  .button-link{
    text-decoration: none;
    display: flex;
  }
  .button{
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-wrapper{
      padding: 10px 19px;
      background: rgba(255, 255, 255, 0.21);
      border-radius: 4px;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      /* identical to box height */
      text-decoration: none;
      color: #FFFFFF;
    }
  }
  .header{
    background: linear-gradient(to bottom, #474747 45%, #545454 55%);
    height: 69px;
    margin-bottom: 60px;
    .container{
        .header-wrapper{
          display: flex;
          justify-content: space-between;
          height: 69px;
          .header-contacts{

            margin-right: 25px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .phone-number{
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 24px;
              /* identical to box height, or 100% */
              color: #FFFFFF;
            }
            .email-link{
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 15px;
              /* identical to box height, or 100% */
              text-decoration-line: underline;
              color: #FFFFFF;
              text-align: right;
              width: 100%;
            }
          }
          &-item{
            display: flex;
            flex-direction: column;
            justify-content: center;
            &-wrapper{
              display: flex;

              .burger-button-link{
                margin-right: 28px;
              }
              .burger-button{
                background: #2A86C2;
                width: 46.56px;
                height: 46.56px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-radius: 50%;
                &-wrapper{
                  display: flex;
                  justify-content: center;
                }

                -webkit-transition: all 0.2s 0s ease;
                -moz-transition: all 0.2s 0s ease;
                -o-transition: all 0.2s 0s ease;
                transition: all 0.2s 0s ease;
              }

              .burger-button:hover{
                background-color: #0467a6;
              }

            }
          }
        }
    }
  }

  @media screen and (max-width: 1000px){
    .header-wrapper-item-right{
      display: none !important;
    }
    .phone-button{
      display: none;
    }
    .button-link{
      display: none;
    }
    .burger-button-link-right{
      display: none;
    }

    .burger-button{
      height: auto !important;
      width: auto !important;
      background: none !important;
    }
    .logo{
      height: 40px;
    }
  }

  @media screen and (max-width: 767px){
    .popup_close{
      display: none !important;
    }
    .popup_close_mobile{
      position: absolute;
      z-index: 501;
      width: 100%;
      display: flex !important;
      justify-content: center;
      top: 20px;

      img{
        height: 40px !important;
      }
    }

    .header {
      margin-bottom: 0;
    }
  }
</style>
