import { createStore } from 'vuex'
import { getProductByLink } from '@/services/methods'

export default createStore({
  state: {
      image: null,
      preview: null,
      doorsPos: null,
      doorsCount: null,
      doorsName: null,
      referrerLink: null,
      referrerDoorInfo: null,
  },
  getters: {
      getImage(state) {
          return state.image;
      },
      getPreview(state) {
        return state.preview;
      },
      getDoorsPos(state) {
        return state.doorsPos;
      },
      getDoorsCount(state) {
          return state.doorsCount;
      },
      getDoorsName(state) {
          return state.doorsName;
      },
      getReferrerLink(state) {
          return state.referrerLink;
      },
      getReferrerDoorInfo(state) {
          return state.referrerDoorInfo;
      },
  },
  mutations: {
      setImage(state, image) {
          state.image = image
      },
      setPreview(state, preview) {
        state.preview = preview
      },
      setDoorsPos(state, doorsPos) {
        state.doorsPos = doorsPos
      },
      setDoorsCount(state, doorsCount) {
        state.doorsCount = doorsCount
      },
      setDoorsName(state, doorsName) {
          state.doorsName = doorsName
      },
      setReferrerLink(state, referrerLink) {
          state.referrerLink = referrerLink;
      },
      setReferrerDoorInfo(state, door) {
          state.referrerDoorInfo = door;
      },
  },
  actions: {
      async getDoorInfo({ commit }, data) {
          try {
              const response = await getProductByLink(data);
              const {status, products, description} = response.data;

              if (status === 'success') {
                  commit('setReferrerDoorInfo', products);
              } else {
                  console.log(` Error: ${description}`);
              }
          } catch (e) {
              console.log(e)
          }
      }
  },
  modules: {
  }
})
