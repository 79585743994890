export const baseUrl = process.env.VUE_APP_BASE_API_URL;
export const baseUrlApi = baseUrl + 'api';
export const baseUrlStorage = baseUrl + 'storage/';
export const enviroment = process.env.VUE_APP_ENV;

const api = {
    settings: {
        get: baseUrlApi + "/settings"
    },
    category: {
        get: baseUrlApi + "/category"
    },
    color: {
        get: baseUrlApi + "/color",
        getColorsInCategory: baseUrlApi + '/colors-in-category/'
    },
    product: {
        getByCategoryId: baseUrlApi + "/products",
        getByLink: baseUrlApi + "/products-by-link"
    },
    property: {
        get: baseUrlApi + "/propertyForFilters",
        getProductIdsByProperties: baseUrlApi + "/products-by-options",
        getPropertiesTextValueFiltered: baseUrlApi + '/products-filtered-text-values'
    },
    mail: {
        order: baseUrlApi + "/send-mail-with-application/",
        callMeLater: baseUrlApi + "/call-me-later"
    },
    detect: {
        get: baseUrlApi + "/detect-doors"
    }
}

export default api;
