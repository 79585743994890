<template lang="html">
  <div class="call_me_later_popup">
    <h4 v-if='!this.formWasSubmit'>
      Оставьте ваш телефон
    </h4>
    <p class='dop-text' v-if='!this.formWasSubmit'>
      Ваш звонок очень важен для нас
    </p>
    <form class='call-me-later-form' v-if='!this.formWasSubmit'>
      <div class="input-box" :class="{'error': this.nameError }">
        <input v-model="name" type="text" name="phone" placeholder="Имя" >
        <span class='error-message'>Заполните имя</span>
      </div>
      <div class="input-box" :class="{'error': this.phoneError }">
        <input v-model="phone" type="tel" name="phone" placeholder="Телефон" >
        <span class='error-message'>Заполните телефон</span>
      </div>
      <button class="CallMeLaterPopupButton button" v-on:click="submitForm($event)">
        Отправить
      </button>
      <div class="privacy-block">
        <label for="privacy">
          <input type="checkbox" name='privacy' v-model="privacy" >
        </label>
        <p :class="{'error': this.checkedError }">Согласие на обработку персональных данных.
          Данное согласие дается Оператору для обработки
          моих персональных данных в следующих целях: - предоставление
          мне услуг/работ; - направление в мой адрес уведомлений,
          касающихся предоставляемых услуг/работ; - подготовка и
          направление ответов на мои запросы; -
          направление в мой адрес информации, в том числе рекламной,
          о мероприятиях/товарах/услугах/работах Оператора.   <a href="#" @click='openPrivacy()'>Правила обработки</a>,
          <a href="#" @click='openPaymentRules()'>Правила оплаты и безопасность платежей конфиденциальность информации</a>,
          </p>

      </div>
    </form>
    <h4 v-if='this.formWasSubmit'>
      Перезвоним вам в ближайшее время!
    </h4>
  </div>


  <div class="popup_dark" style='z-index: 600' v-if='this.popupVisible' @click='closePopup()'></div>
  <div class="popup_close" v-if='this.popupVisible' @click='closePopup()'><img src="@/assets/icon/modal-close.png"></div>
  <div class="popup_close_mobile" v-if='this.popupVisible' @click='closePopup()'><img src="@/assets/icon/cross_mobb.png"></div>

  <div v-if='this.privacyPopupVisible || this.publicOfertaPopupVisible || this.paymentRulesPopupVisible' class="popup_wrapper" @click='closePopup()'>
    <PrivacyPopup v-if='this.privacyPopupVisible'/>
    <PublicOfertaPopup v-if='this.publicOfertaPopupVisible'/>
    <PaymentRulesPopup v-if='this.paymentRulesPopupVisible'/>
  </div>
</template>

<script>
import { sendMessageCallMeLater } from "@/services/methods.js";
import PrivacyPopup from './PrivacyPopup';
import PublicOfertaPopup from './PublicOfertaPopup';
import PaymentRulesPopup from './PaymentRulesPopup';

export default {
  name: 'CallMeLaterPopup',
  data(){
    return {
      phoneError: false,
      nameError: false,
      formWasSubmit: false,
      popupVisible: false,
      privacyPopupVisible: false,
      publicOfertaPopupVisible: false,
    }
  },
  components: {
    PrivacyPopup,
    PublicOfertaPopup,
    PaymentRulesPopup
  },
  methods:{
    closePopup(){
      this.popupVisible = false;
      this.privacyPopupVisible = false;
      this.publicOfertaPopupVisible = false;
      this.paymentRulesPopupVisible = false;
    },
    openPrivacy(){
      this.popupVisible = true;
      this.privacyPopupVisible = true;
    },
    openPublicOferta(){
      this.popupVisible = true;
      this.publicOfertaPopupVisible = true;
    },
    openPaymentRules(){
      this.popupVisible = true;
      this.paymentRulesPopupVisible = true;
    },
    async submitForm(e){
      e.preventDefault();

      this.nameError = (this.name == null || this.name.trim() == "");
      this.phoneError = (this.phone == null || this.phone.trim() == "");
      this.checkedError = !this.privacy;

      if (this.nameError || this.phoneError || this.checkedError) {
        return;
      }

      const response = await sendMessageCallMeLater({
        name: this.name,
        phone: this.phone
      });

      this.formWasSubmit = response.data.status === "success";
    }
  }
}
</script>

<style lang="scss" scoped>
  .CallMeLaterPopupButton:hover{
    background-color: #0467a6;
    color: white;
  }

  .popup_wrapper{
    z-index: 601;
    width: 100vw;
    display: flex;
    position: absolute;
    justify-content: center;
  }
  .popup_close{
    position: absolute;
    left: calc(50vw + 460px);
    z-index: 601;
    top: 30px;
    cursor: pointer;
  }
  .popup_close_mobile{
    display: none;
  }
  .input-box{
    margin-bottom: 15px;

    input{
      color: #181818;
      border: 2px solid #d5d5d5;
      padding: 18px;
      width: 100%;
      border-radius: 5px;
    }

  }

  .error-message{
    display: none;
    margin-top: 5px;
    font-size: 12px;
    margin-bottom: -5px;
    color: #a94242;
  }

  .error{
    .error-message{
      display: block !important;
    }

    input{
      border: 1px solid #a94242;
    }
  }

  .call_me_later_popup{
    z-index: 501;
    width: 366px;
    padding: 30px 25px;
    position: absolute;
    background: #f6f6ef;
    top:30px;

    .dop-text{
      font-size: 14px;
      line-height: 20px;
      color: #8c8c8c;
      margin: 0px 0 25px;
      text-align: center;
    }

    .button{
      border: none;
      width: 100%;
      height: 60px;
      margin-top: 30px;
      font-size: 17px;
      font-weight: 100;
    }

    .privacy-block{
      margin-top: 15px;

      input{
        margin: 0;
        margin-right: 8px;
        position: absolute;
        margin-top: 3px;
        font-size: 16px;
      }

      p{
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        color: #8c8c8c;
        padding-left: 24px;

        a{
          text-align: left;
          color: #2285c4;
          text-decoration: none;
        }
      }
    }

    h4{
      font-size: 20px;
      line-height: 25px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 20px;
      font-weight: 600;
    }
  }


  @media screen and (max-width: 767px){
    .call_me_later_popup{
      z-index: 501;
      width: 95vw;
      padding: 30px 25px;
      position: absolute;
      background: #f6f6ef;
      top: 80px;
    }
  }
</style>
