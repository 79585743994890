import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from "vue-sweetalert2";

import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/fonts/Lato/stylesheet.css'
import './assets/css/bootstrap-grid.css'
import './assets/css/main.css'
import './directives/tooltip.css'

createApp(App).use(store).use(router).use(VueAxios, axios).use(VueSweetalert2).mount('#app')
