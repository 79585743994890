<template lang="html">
  <Header />
  <div class="container">
    <form class='order-form' v-if="!formWasSubmit">
      <h3 v-if='!this.formWasSubmit'>ОСТАВЬТЕ ВАШИ КОНТАКТЫ</h3>
      <h3 v-if='this.formWasSubmit'> Спасибо!</h3>
      <h3 v-if='this.formWasSubmit'> Свяжемся с вами в ближайшее время!</h3>
      <div v-if='!this.formWasSubmit' class="input-box" :class="{'error': this.phoneError }">
        <input v-model="phone" type="tel" name="phone" placeholder="Телефон" v-mask="'+7(999)999-99-99'">
        <span class='error-message'>Заполните телефон</span>
      </div>
      <!--<div v-if='!this.formWasSubmit' class="input-box" :class="{'error': this.paymentsMethodError }">
        <label for="name">Способ оплаты:</label>
        <select v-model="paymentsMethod" class="" name="">
          <option value="Банковская карта">Банковская карта</option>
          <option value="Наличные">Наличные</option>
        </select>
        <span class='error-message'>Выберите способ оплаты</span>
        <input type="text" name="name" value="4" readonly>
      </div>
      <div v-if='!this.formWasSubmit' class="input-box" :class="{'error': this.deliveryError }">
        <label for="name">Доставка:</label>
        <select v-model="delivery" class="" name="">
          <option value="Самовывоз">Самовывоз</option>
          <option value="Доставка внутри МКАД">Доставка внутри МКАД</option>
          <option value="Доставка за МКАД">Доставка за МКАД</option>
          <option value="егионы России">Регионы России</option>
        </select>
        <span class='error-message'>Выберите способ доставки</span>
        <input type="text" name="name" value="4" readonly>
      </div>
      <div v-if='!this.formWasSubmit' class="input-box">
        <label for="name">Превью:</label>
        <img v-if="!image_data" src="@/assets/images/backgrounds/photo_choice_example.jpeg" alt="">
        <img v-else :src="image_data" alt="">
        <input type="text" name="name" value="4" readonly>
      </div>-->
      <input type="hidden" name="" :value="this.doorName">
      <div v-if='!this.formWasSubmit' class="input-box">
        <label for="name">Количество дверей:  {{ this.doors }}</label>
        <!--<input type="text" name="name" value="4" readonly>-->
      </div>
      <button v-if='!this.formWasSubmit' class="button" v-on:click="submitForm($event)">
        Отправить
      </button>
    </form>
    <div class="success_order" v-if='this.formWasSubmit'>
      <div class="success_order_content">
        Спасибо за заявку! В ближайшее время с вами свяжется наш менеджер
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Header from '@/components/include/Header.vue'
import {
  sendMessageOrder
} from "../services/methods.js";
import {mapMutations, mapGetters} from 'vuex'

export default {
  name: "OrderView",
  components: {
    Header
  },
  mounted() {
    this.image_data = this.getPreview
    this.doors = this.getDoorsCount
  },
  data() {
    return {
      phoneError: false,
      deliveryError: false,
      paymentsMethodError: false,
      formWasSubmit: false,
      // TODO: Пока что это заглушки
      countDoors: 3,
      doorName: "Какая-то дверь",
      doors: null,
      image_data: null,
      formSend: false
    }
  },
  computed: {
    ...mapGetters(["getPreview", "getDoorsCount", "getDoorsName"]),
  },
  methods: {
    async submitForm(e){
      e.preventDefault();

      //this.paymentsMethodError = false;
      this.phoneError = false;
      //this.deliveryError = false;

      if (this.phone == null || this.phone.trim() == ""/* ||
          this.paymentsMethod == null || this.delivery == null*/) {

          if (this.phone == null || this.phone.trim() == "") {
            this.phoneError = true;
          }

          /*if (this.paymentsMethod == null) {
            this.paymentsMethodError = true;
          }

          if (this.delivery == null) {
            this.deliveryError = true;
          }*/

          return;
      }

      const response = await sendMessageOrder({
        phone: this.phone,
        /*delivery: this.delivery,
        paymentsMethod: this.paymentsMethod,*/
        countDoors: this.doors,
        productName: this.getDoorsName ? this.getDoorsName : this.doorName
      });

      if (response.data.status === "success") {
        this.formWasSubmit = true;
      }else{
        this.$swal.fire({
            icon: "error",
            text: "Не удалось отправить заявку!",
            title: "Ошибка"
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .success_order {
    width: 400px;
    margin: 0 auto;
    padding: 40px 35px 40px;
    background-color: #f6f6ef;
    border-radius: 5px;
    font-size: 16px;
    line-height: 22px;
    color: #181818;
    &_content {

    }
  }
  .order-form{
    width: 400px;
    margin: 0 auto;
    padding: 40px 35px 40px;
    background-color: #f6f6ef;
    border-radius: 5px;
    font-size: 16px;
    line-height: 22px;
    color: #181818;

    .error{
      input, select{
        border: 1px solid #a94242 !important;
      }

      .error-message{
        display: block !important;
      }
    }

    .button{
      border: none;
      width: 100%;
    }

    h3{
      font-size: 21px;
      line-height: 25px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 40px;
      font-weight: bold;
    }

    .input-box, .preview-box{
      display: flex;
      flex-direction: column;

      .error-message{
        margin-top: -10px;
        margin-bottom: 10px;
        color: #a94242;
        display: none;
      }

      img{
        //height: 300px;
        object-fit: cover;
        border: 2px solid #d5d5d5;
        border-radius: 5px;
      }

      label{
        font-size: 18px;
        line-height: 22px;
        margin: 10px 0 20px;
        font-weight: bold;
      }

      input, select, option{
        min-height: 43px;
        border-radius: 5px;
        border: 1px solid grey;
        margin: 0 0 15px;
        font-size: 15px;
        line-height: 15px;
        width: 100%;
        display: block;
        background: #fff;
        padding: 14px 12px 15px 12px;
        border: 2px solid #d5d5d5;
        color: #767676;
      }

      .date-time-input{
        margin-bottom: 10px;
      }
    }
  }

  @media screen and (max-width: 1000px){
    .order-form{
      width: auto;
    }
  }
</style>
