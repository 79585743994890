<template>
  <div class="popup_dark" v-if='this.popupVisible' @click='closePopup()'></div>
  <div class="popup_close" v-if='this.popupVisible' @click='closePopup()'><img src="@/assets/icon/modal-close.png"></div>
  <div class="popup_close_mobile" v-if='this.popupVisible' @click='closePopup()'><img src="@/assets/icon/cross_mobb.png"></div>
  <div v-if='this.privacyPopupVisible || this.publicOfertaPopupVisible || this.paymentRulesPopupVisible' class="popup_wrapper">
    <PrivacyPopup v-if='this.privacyPopupVisible'/>
    <PublicOfertaPopup v-if='this.publicOfertaPopupVisible'/>
    <PaymentRulesPopup v-if='this.paymentRulesPopupVisible'/>
  </div>

  <div class="popup_dark" v-if='this.callMeLaterPopupVisible' @click='callMeLaterPopupOpen()'></div>
  <div class="popup_wrapper_callmelater" v-if='this.callMeLaterPopupVisible'>
    <CallMeLaterPopup v-if='this.callMeLaterPopupVisible'/>
  </div>
  <div class="popup_close_callmelater" v-if='this.callMeLaterPopupVisible' @click='callMeLaterPopupOpen()'><img src="@/assets/icon/modal-close.png"></div>
  <div class="popup_close_callmelater_mobile" v-if='this.callMeLaterPopupVisible' @click='callMeLaterPopupOpen()'><img src="@/assets/icon/cross_mobb.png"></div>

  <footer>
    <div class="footer-top">
      <div class="container">
        <div class="footer-top-wrapper">
          <div class="footer-column">
            <a href="tel:+7 499 707-79-96" class='footer-link'>
              <div class="phone-footer-link">
                <img class='phone-footer-link-img' src="../../assets/icon/phone-link.svg" alt="">
                <div class="">
                  +7 499 707-79-96
                </div>
              </div>
            </a>
            <a href="tel:+7 499 707-79-58" class='footer-link'>
              <div class="phone-footer-link">
                <img class='phone-footer-link-img' src="../../assets/icon/phone-link.svg" alt="">
                <div class="">
                  +7 499 707-79-58
                </div>
              </div>
            </a>
            <a href="#" @click='callMeLaterPopupOpen()' class='footer-link'>
              <div class="footer-button">
                Заказать звонок
              </div>
            </a>
          </div>
          <div class="footer-column">
            <div class="footer-column-middle">
              <p>
                Копирование, размножение, распространение, перепечатка (целиком или частично),
                или иное использование материала без письменного разрешения автора не допускается.
                Любое нарушение прав автора будет преследоваться на основе российского законодательства. .
              </p>
              <p class='footer-links_documents footer-links'>
                <a href="#" @click='openPrivacy()'>Обработка персональных данных</a>
                <a href="#" @click='openPublicOferta()'>Публичная оферта</a><br>
                <a href="#" @click='openPaymentRules()'>Правила оплаты и безопасность платежей, конфиденциальность информации</a>
              </p>
            </div>
          </div>
          <div class="footer-column">
            <div class="social-links">
              <a href="https://vk.com/line_doors_official" target="_blank">
                <img src="../../assets/icon/vk.png" alt="">
              </a>
              <a href="https://www.youtube.com/channel/UC4eU1QhMVChC2au26LGo-SA" target="_blank">
                <img src="../../assets/icon/youtube.png" alt="">
              </a>
              <a href="https://t.me/linedoors" target="_blank">
                <img src="../../assets/icon/telegram.png" alt="">
              </a>
            </div>
            <div class="email-link">
              <img src="../../assets/icon/mail.svg" alt="">
              <a href="mailto:info@linedoors.com">
                info@linedoors.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        © ООО "Линия Дверей" , 2004-{{localeDate}}
      </div>
    </div>
  </footer>
</template>

<script>
import PrivacyPopup from './PrivacyPopup';
import PublicOfertaPopup from './PublicOfertaPopup';
import CallMeLaterPopup from './CallMeLaterPopup';
import PaymentRulesPopup from './PaymentRulesPopup';

export default {
  name: "Footer",
  components: {
    PrivacyPopup,
    PublicOfertaPopup,
    PaymentRulesPopup,
    CallMeLaterPopup,
  },
  data(){
    return{
      popupVisible: false,
      privacyPopupVisible: false,
      publicOfertaPopupVisible: false,
      callMeLaterPopupVisible: false,
    }
  },
  computed: {
    localeDate() {
      return (new Date().getFullYear())
    },
  },
  methods: {
    callMeLaterPopupOpen(){
      this.callMeLaterPopupVisible = !this.callMeLaterPopupVisible;
    },
    closePopup(){
      this.popupVisible = false;
      this.privacyPopupVisible = false;
      this.publicOfertaPopupVisible = false;
      this.paymentRulesPopupVisible = false;
    },
    openPrivacy(){
      this.popupVisible = true;
      this.privacyPopupVisible = true;
    },
    openPublicOferta(){
      this.popupVisible = true;
      this.publicOfertaPopupVisible = true;
    },
    openPaymentRules(){
      this.popupVisible = true;
      this.paymentRulesPopupVisible = true;
    }
  }
}
</script>

<style lang="scss" scoped>
  .popup_wrapper_callmelater{
    position: absolute;
    width: 100%;
    top: 0px;
    display: flex;
    justify-content: center;
  }
  .popup_close_callmelater{
    position: absolute;
    z-index: 501;
    top: 30px;
    left: calc(50% + 200px);
  }
  .popup_close_callmelater_mobile{
    display: none;
  }
  .popup_close_mobile{
    display: none
  }
  .popup_wrapper{
    position: absolute;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .popup_close{
    z-index: 501;
    top: 30px;
    position: absolute;
    left: calc(50% + 470px);
    cursor: pointer;
  }

  footer{
    margin-top: 200px;
  }
  .footer-top{
    background: rgba(0, 0, 0, 0.45);
    padding: 40px 0px;
    .footer-top-wrapper{
      display: flex;
      justify-content: space-between;

      .footer-column{
        .email-link{
          display: flex;
          margin-top: 36px;

          a{
            margin-left: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 15px;
            /* identical to box height, or 100% */
            color: #FFFFFF;
            text-decoration: none;
            border-bottom: 1px solid #DEDEDE;
          }
        }

        .social-links{
          display: flex;

          a{
            margin-right: 10px;
            display: block;

            img{
              height: 30px;
            }
          }
        }
        &-middle{
          width: 555px;

          p{
            font-style: normal;
            font-weight: 500;
            font-size: 13.5px;
            line-height: 20px;
            /* or 143% */
            color: #CFCFCF;
            margin-bottom: 25px;

            a{
              font-style: normal;
              font-weight: 500;
              font-size: 13.5px;
              line-height: 20px;
              /* or 143% */
              color: #CFCFCF;
              margin-right: 35px;
            }
          }
        }
      }
      .footer-link{
        text-decoration: none;
        margin-bottom: 15px;
        display: block;

        .footer-button{
          background: #2285C4;
          border-radius: 6px;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 15px;
          /* identical to box height, or 100% */
          text-align: center;
          color: #FFFFFF;
          padding: 12px 0px 15px 0px;
          margin-top: 20px;
          padding: 14px 30px;
          width: fit-content;

          -webkit-transition: all 0.2s 0s ease;
          -moz-transition: all 0.2s 0s ease;
          -o-transition: all 0.2s 0s ease;
          transition: all 0.2s 0s ease;
        }

        .footer-button:hover{
          background-color: #0467a6;
        }
      }
      .phone-footer-link{
        display: flex;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 19px;
        /* identical to box height, or 106% */
        color: #FFFFFF;

        &-img{
          margin-right: 10px;
        }
      }
    }
  }
  .footer-bottom{
    padding: 15px 0px;
    background: #626262;

    .container{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */
      color: #CFCFCF;
    }
  }

  @media screen and (max-width: 767px){

    .footer-top .footer-top-wrapper .footer-link .footer-button{
      margin-bottom: 20px;
      margin-top: 5px;
    }
    .footer-top .footer-top-wrapper .footer-column .social-links a{
      margin-right: 5px;
    }
    .footer-top .footer-top-wrapper .footer-column .social-links a img{
      height: 40px;
    }
    .footer-links_documents{
      text-align: left;
      a{
        margin-right: 10px !important;
      }
    }
    .popup{
      margin-top: 0px;
    }
    .popup_wrapper{
      margin-top: 80px !important;
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
    .popup_wrapper{
      justify-content: flex-start !important;
    }
    .popup_close{
      display: none;
    }
    .popup_close_mobile{
      display: block;
      position: absolute;
      z-index: 501;
      left: 50%;
      margin-top: 20px;

      img{
        width: 40px;
        height: 40px;
      }
    }
  }

  @media screen and (max-width: 1000px){
    .footer-top-wrapper{
      flex-direction: column;
      text-align: center;
    }
    .social-links{
      justify-content: center;
    }
    .phone-footer-link{
      justify-content: center;
    }
    .email-link{
      justify-content: center;
    }
    .footer-column-middle{
      width: auto !important;
    }
    .popup_close {
      left: calc(50% + 340px) !important;
    }
    .footer-link{
      display: flex !important;
      justify-content: center;

      .footer-button{
        margin-top: 10px;
      }
    }
  }

  @media screen and (max-width: 767px){
    .popup_close_callmelater{
      display: none !important;
    }

    .popup_close_callmelater_mobile{
      display: block !important;
      z-index: 501;
      position: absolute;
      left: 48%;
      top: 30px;

      img{
        height: 40px;
      }
    }
    .popup_wrapper_callmelater{
      position: absolute;
      width: 100%;
      top: 70px;
      display: flex;
      justify-content: center;
    }
  }


</style>
