<template lang="html">
  <div class='popup' id="publicOferta">
    <p class='popup-title popup-title-general'>
      Правила оплаты и безопасность платежей, конфиденциальность информации
    </p>
    <div class="paymentsImage">
      <img src="@/assets/images/payments.jpeg" alt="">
    </div>
    <p>
      Услуга оплаты через интернет осуществляется в соответствии с Правилами международных платежных систем Visa, MasterCard и Платежной системы МИР на принципах соблюдения конфиденциальности и безопасности совершения платежа, для чего используются самые современные методы проверки, шифрования и передачи данных по закрытым каналам связи. Ввод данных банковской карты осуществляется на защищенной платежной странице АО «АЛЬФА-БАНК».
    </p>
    <p>
      На странице для ввода данных банковской карты потребуется ввести данные банковской карты: номер карты, имя владельца карты, срок действия карты, трёхзначный код безопасности (CVV2 для VISA, CVC2 для MasterCard, Код Дополнительной Идентификации для МИР). Все необходимые данные пропечатаны на самой карте. Трёхзначный код безопасности — это три цифры, находящиеся на обратной стороне карты.
    </p>
    <p>
      Случаи отказа в совершении платежа:
      <ul class='list'>
        <li>банковская карта не предназначена для совершения платежей через интернет, о чем можно узнать, обратившись в Ваш Банк;</li>
        <li>недостаточно средств для оплаты на банковской карте. Подробнее о наличии средств на банковской карте Вы можете узнать, обратившись в банк, выпустивший банковскую карту;</li>
        <li>данные банковской карты введены неверно;</li>
        <li>истек срок действия банковской карты. Срок действия карты, как правило, указан на лицевой стороне карты (это месяц и год, до которого действительна карта). Подробнее о сроке действия карты Вы можете узнать, обратившись в банк, выпустивший банковскую карту;</li>
      </ul>
      <br><br>
      По вопросам оплаты с помощью банковской карты и иным вопросам, связанным с работой сайта, Вы можете обращаться по следующим телефонам: 8 (499) 707-79-96 .
      <br><br>
      Предоставляемая вами персональная информация (имя, адрес, телефон, e-mail, номер банковской карты) является конфиденциальной и не подлежит разглашению. Данные вашей кредитной карты передаются только в зашифрованном виде и не сохраняются на нашем Web-сервере.
    </p>

    <p class='popup-title'>Правила возврата товара</p>
    <p>
      При оплате картами возврат наличными денежными средствами не допускается. Порядок возврата регулируется правилами международных платежных систем.
      Процедура возврата товара регламентируется статьей 26.1 федерального закона «О защите прав потребителей».
      <br><br>
      <ul class='list'>
        <li>При производстве товара под заказ (по индивидуальным замерам) Потребитель не вправе отказаться от товара, кроме случаев наличия брака;</li>
        <li>Возврат стандартного не использованного товара надлежащего качества возможен в случае, если товар в заводской упаковке, при наличии документа, подтверждающего факт и условия покупки указанного товара;</li>
        <li>Потребитель не вправе отказаться от товара надлежащего качества, имеющего индивидуально-определенные свойства, если указанный товар может быть использован исключительно приобретающим его человеком;</li>
        <li>При отказе потребителя от товара продавец должен возвратить ему денежную сумму, уплаченную потребителем по договору, за исключением расходов продавца на доставку от потребителя возвращенного товара, не позднее чем через десять дней со дня предъявления потребителем соответствующего требования;</li>
      </ul>
      <br><br>

      Для возврата денежных средств на банковскую карту необходимо заполнить «Заявление о возврате денежных средств», которое высылается по требованию компанией на электронный адрес и оправить его вместе с приложением копии паспорта по адресу info@linedoors.com
      Возврат денежных средств будет осуществлен на банковскую карту в течение 21 (двадцати одного) рабочего дня со дня получения «Заявление о возврате денежных средств» Компанией.
      Для возврата денежных средств по операциям проведенными с ошибками необходимо обратиться с письменным заявлением и приложением копии паспорта и чеков/квитанций, подтверждающих ошибочное списание. Данное заявление необходимо направить по адресу info@linedoors.com
      Сумма возврата будет равняться сумме покупки. Срок рассмотрения Заявления и возврата денежных средств начинает исчисляться с момента получения Компанией Заявления и рассчитывается в рабочих днях без учета праздников/выходных дней.
    </p>

    <p class='popup-title'>Контактная информация</p>
    <p>
      <ul class='list'>
        <li>ООО "Линия Дверей"</li>
        <li>г.Москва ул.Коминтерна д.7 корпус 2</li>
        <li>основной государственный регистрационный номер (ОГРН) — 5167746297615</li>
        <li>идентификационный номер налогоплательщика (ИНН) — 7716838815</li>
        <li>код причины постановки на учет (КПП) — 771601001</li>
        <li>общероссийский классификатор территорий муниципальных образований (ОКТМО) — 45351000000</li>
        <li>телефон (499) 707-79-96</li>
      </ul>
    </p>
  </div>
</template>

<script>
export default {
  name: "PaymentRulesPopup"
}
</script>

<style lang="scss" scoped>
  .popup{
    background: #fff;
    padding: 30px 37px 30px;
    z-index: 601;
    margin-top: 30px;
    border-radius: 6px;
    line-height: 23px;
    width: 900px;
    height: fit-content;
    font-family: 'SegoeUIRegular', Arial, 'PT Sans Caption' !important;

    .popup-title{
      font-size: 27px;
      line-height: 32px;
      margin-bottom: 25px;
      text-align: left;
      font-weight: 600;
      margin-top: 55px;
    }

    .popup-title-general{
      margin-top: 0px;
      font-size: 27px;
      line-height: 32px;
      font-family: 'LatoBold', Arial, 'PT Sans Caption';
      font-weight: 500;
      text-align: center;
      margin-bottom: 10px;
    }

    p{
        font-size: 15.5px;
        line-height: 23px;
        margin-bottom: 30px;
        margin-top: 20px;
        font-size: 16px;
        line-height: 23px;
        /* margin-top: 0; */
        margin-bottom: 10px;
    }
  }

  .list{
    list-style-type: disc;
    padding-left: 35px;
    line-height: 23px;
  }
  .paymentsImage{
    width: 100%;
    text-align: center;

    img{
      width: 604px;
    }
  }

  @media screen and (max-width: 1000px){
    .popup{
      width: 650px !important;
    }
    .paymentsImage{
      width: 100%;
      text-align: center;

      img{
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 767px){
    .popup{
      width: calc(100% - 30px) !important;
    }
  }
</style>
